import { useMutation } from "@apollo/react-hooks";
import { gql } from "graphql.macro";
import React from "react";

import { LogoutMutation, LogoutMutationVariables } from "../__generated__/types";

const LOGOUT_MUTATION = gql`
  mutation logout {
    logout @client
  }
`;

function NavbarUserLogoutButton(): React.ReactElement {
  const [logout] = useMutation<LogoutMutation, LogoutMutationVariables>(LOGOUT_MUTATION);
  return (
    <button className="dropdown-item" type="button" onClick={() => logout()}>
      Logout
    </button>
  );
}

export default NavbarUserLogoutButton;
