import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import NavbarAnonymous from "./NavbarAnonymous";
import NavbarEntities from "./NavbarEntities";
import NavbarUser from "./NavbarUser";

interface Props {
  userID: string | undefined | null;
}

function Navbar({ userID }: Props): React.ReactElement {
  const [isCollapsed, setCollapsed] = useState(true);
  const togglerClass = classNames("navbar-toggler", {
    collapsed: isCollapsed,
  });
  const collapseClass = classNames("collapse", "navbar-collapse", {
    show: !isCollapsed,
  });
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top border-bottom">
      <div className="container">
        <Link to="/" className="navbar-brand">
          feastr
        </Link>
        <button className={togglerClass} type="button" onClick={() => setCollapsed(!isCollapsed)}>
          <span className="navbar-toggler-icon" />
        </button>
        <div className={collapseClass}>
          {userID ? (
            <>
              <NavbarEntities />
              <NavbarUser userID={userID} />
            </>
          ) : (
            <>
              <ul className="navbar-nav mr-auto" />
              <NavbarAnonymous />
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
