import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";

import { createApiLink } from "./link/api";
import { createAuthLink } from "./link/auth";
import { resolvers } from "./state";

export function createClient(): ApolloClient<NormalizedCacheObject> {
  const cache = new InMemoryCache();
  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([createAuthLink(), createApiLink()]),
    name: "admin2",
    resolvers,
    version: "1", // TODO
  });
  return client;
}
