import { useMutation } from "@apollo/react-hooks";
import { gql } from "graphql.macro";
import React from "react";

import { LoginMutation, LoginMutationVariables } from "../__generated__/types";

const LOGIN_MUTATION = gql`
  mutation login {
    login @client
  }
`;

function NavbarAnonymousLoginButton(): React.ReactElement {
  const [login] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN_MUTATION);
  return (
    <button className="btn btn-outline-primary" type="button" onClick={() => login()}>
      Login
    </button>
  );
}

export default NavbarAnonymousLoginButton;
