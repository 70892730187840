export const config =
  process.env.NODE_ENV === "production"
    ? {
        apiBase: "https://api.feastr-dev.de",
        oauth2: {
          clientId: "34958419-1f7c-4880-9dbb-edcec048f3a7",
          redirect: "https://admin2.feastr-dev.de/callback.html",
          url: "https://id.feastr-dev.de/oauth2/authorize",
        },
      }
    : {
        apiBase: "https://api.feastr-dev.de",
        oauth2: {
          clientId: "d1e35a86-fd05-4ac9-b320-e2408c050066",
          redirect: "http://localhost:3000/callback.html",
          url: "https://id.feastr-dev.de/oauth2/authorize",
        },
      };
