import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function NavbarEntities(): React.ReactElement {
  const [isCollapsed, setCollapsed] = useState(true);
  const itemClass = classNames("nav-item", "dropdown", {
    show: !isCollapsed,
  });
  const menuClass = classNames("dropdown-menu", {
    show: !isCollapsed,
  });
  return (
    <ul className="navbar-nav mr-auto">
      <li
        className={itemClass}
        onBlur={({ currentTarget }) => {
          setTimeout(() => {
            if (!currentTarget.contains(document.activeElement)) {
              setCollapsed(true);
            }
          }, 0);
        }}
      >
        <button
          className="btn btn-link border-0 nav-link dropdown-toggle"
          type="button"
          onClick={() => setCollapsed(!isCollapsed)}
        >
          Entities
        </button>
        <div className={menuClass}>
          <Link to="/user" className="dropdown-item" onClick={() => setCollapsed(true)}>
            User
          </Link>
        </div>
      </li>
    </ul>
  );
}

export default NavbarEntities;
