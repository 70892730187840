import React from "react";

import NavbarAnonymousLoginButton from "./NavbarAnonymousLoginButton";

function NavbarAnonymous(): React.ReactElement {
  return (
    <form className="form-inline">
      <NavbarAnonymousLoginButton />
    </form>
  );
}

export default NavbarAnonymous;
