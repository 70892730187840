import cuid from "cuid";
import * as querystring from "query-string";

import { config } from "../../environment";

function openPopup(url: string, name: string, width: number, height: number): Window | null {
  const left = (window.screenLeft || window.screenX) + window.innerWidth / 2 - width / 2;
  const top = (window.screenTop || window.screenY) + window.innerHeight / 2 - height / 2;

  const settings =
    "scrollbars=no,toolbar=no,location=no,titlebar=no,directories=no,status=no," +
    `menubar=no,width=${width},height=${height},top=${top},left=${left}`;

  return window.open(url, name, settings);
}

interface AuthorizeResponse {
  userID: string;
  token: string;
}

function listenForCredentials(
  popup: Window,
  state: string,
  resolve: (resp: AuthorizeResponse) => void,
  reject: (err: string) => void,
): void {
  let hash;
  try {
    hash = popup.location.hash;
  } catch (err) {
    // Swallow
  }

  if (hash) {
    popup.close();

    const response = querystring.parse(hash.substr(1));
    if (response.state !== state) {
      reject("Invalid state returned.");
    }

    if (typeof response.user === "string" && typeof response.access_token === "string") {
      resolve({
        token: response.access_token,
        userID: response.user,
      });
    } else if (typeof response.error === "string") {
      reject(response.error);
    } else {
      reject("Unknown error.");
    }
  } else if (popup.closed) {
    reject("Authentication was cancelled.");
  } else {
    setTimeout(() => listenForCredentials(popup, state, resolve, reject), 100);
  }
}

export function authorize(): Promise<AuthorizeResponse> {
  const state = cuid();
  const url =
    config.oauth2.url +
    "?" +
    querystring.stringify({
      client_id: config.oauth2.clientId,
      redirect_uri: config.oauth2.redirect,
      response_type: "token",
      state,
    });

  const popup = openPopup(url, "oauth2", 400, 400);
  if (!popup) {
    return Promise.reject("Couldn't create popup.");
  }

  return new Promise((resolve, reject) => listenForCredentials(popup, state, resolve, reject));
}
