import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";

import { config } from "../../environment";

export function createApiLink(): ApolloLink {
  return new HttpLink({
    uri: `${config.apiBase}/graphql`,
  });
}
