import { useQuery } from "@apollo/react-hooks";
import classNames from "classnames";
import { gql } from "graphql.macro";
import React, { useState } from "react";

import { MeQuery, MeQueryVariables } from "../__generated__/types";
import NavbarUserLogoutButton from "./NavbarUserLogoutButton";

const ME_QUERY = gql`
  query me($id: ID!) {
    me: user(id: $id) {
      id
      name
    }
  }
`;

interface Props {
  userID: string;
}

function NavbarUser({ userID }: Props): React.ReactElement {
  const { data, loading } = useQuery<MeQuery, MeQueryVariables>(ME_QUERY, {
    variables: { id: userID },
  });
  const [isCollapsed, setCollapsed] = useState(true);
  const itemClass = classNames("nav-item", "dropdown", {
    show: !isCollapsed,
  });
  const menuClass = classNames("dropdown-menu", "dropdown-menu-right", {
    show: !isCollapsed,
  });
  return (
    <ul className="navbar-nav">
      <li
        className={itemClass}
        onBlur={({ currentTarget }) => {
          setTimeout(() => {
            if (!currentTarget.contains(document.activeElement)) {
              setCollapsed(true);
            }
          }, 0);
        }}
      >
        <button
          className="btn btn-link border-0 nav-link dropdown-toggle"
          type="button"
          onClick={() => setCollapsed(!isCollapsed)}
        >
          {loading ? (
            <div className="spinner-border spinner-border-sm" />
          ) : data && data.me ? (
            data.me.name
          ) : (
            "unknown"
          )}
        </button>
        <div className={menuClass}>
          <NavbarUserLogoutButton />
        </div>
      </li>
    </ul>
  );
}

export default NavbarUser;
