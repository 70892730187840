import { Auth, AuthQuery } from "../../__generated__/types";
import { authorize } from "../oauth2";
import { Context } from "../types";

function auth(): Auth {
  return {
    __typename: "Auth",
    token: null,
    userID: null,
  };
}

async function login(_parent: unknown, _args: unknown, context: Context): Promise<boolean> {
  const resp = await authorize();
  context.cache.writeData<AuthQuery>({
    data: {
      auth: {
        __typename: "Auth",
        ...resp,
      },
    },
  });
  return true;
}

function logout(_parent: unknown, _args: unknown, context: Context): boolean {
  context.cache.writeData<AuthQuery>({
    data: {
      auth: {
        __typename: "Auth",
        token: null,
        userID: null,
      },
    },
  });
  return true;
}

export const resolvers = {
  Mutation: {
    login,
    logout,
  },
  Query: {
    auth,
  },
};
