import React from "react";
import { Route, Switch } from "react-router-dom";

import Dashboard from "./dashboard/Dashboard";
import UserList from "./user/UserList";

function Routes(): React.ReactElement {
  return (
    <Switch>
      <Route exact={true} path="/">
        <Dashboard />
      </Route>
      <Route exact={true} path="/user">
        <UserList />
      </Route>
    </Switch>
  );
}

export default Routes;
