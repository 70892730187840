import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { loader } from "graphql.macro";

import { AuthQuery, AuthQueryVariables } from "../../__generated__/types";
import { Context } from "../types";

const AUTH_QUERY = loader("./auth.gql");

function setAuthHeader(_op: unknown, context: Context): Partial<Context> | void {
  const data = context.cache.readQuery<AuthQuery, AuthQueryVariables>({ query: AUTH_QUERY });
  if (!data || !data.auth.token) {
    return;
  }
  return {
    headers: {
      authorization: `Bearer ${data.auth.token}`,
      ...context.headers,
    },
  };
}

export function createAuthLink(): ApolloLink {
  return setContext(setAuthHeader);
}
