import { useQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { AuthQuery, AuthQueryVariables } from "../__generated__/types";
import Routes from "../routes/Routes";
import styles from "./App.module.css";
import Navbar from "./Navbar";

const AUTH_QUERY = loader("../api/link/auth.gql");

function App(): React.ReactElement {
  const { data } = useQuery<AuthQuery, AuthQueryVariables>(AUTH_QUERY);
  const userID = data && data.auth.userID;
  return (
    <Router>
      <Navbar userID={userID} />
      <div className={"container " + styles.content}>{userID && <Routes />}</div>
    </Router>
  );
}

export default App;
